// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("./../../../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cart-index-js": () => import("./../../../src/pages/cart/index.js" /* webpackChunkName: "component---src-pages-cart-index-js" */),
  "component---src-pages-checkout-error-index-js": () => import("./../../../src/pages/checkout/error/index.js" /* webpackChunkName: "component---src-pages-checkout-error-index-js" */),
  "component---src-pages-checkout-finish-index-js": () => import("./../../../src/pages/checkout/finish/index.js" /* webpackChunkName: "component---src-pages-checkout-finish-index-js" */),
  "component---src-pages-checkout-index-js": () => import("./../../../src/pages/checkout/index.js" /* webpackChunkName: "component---src-pages-checkout-index-js" */),
  "component---src-pages-checkout-pending-index-js": () => import("./../../../src/pages/checkout/pending/index.js" /* webpackChunkName: "component---src-pages-checkout-pending-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-net-package-netpack-index-js": () => import("./../../../src/pages/net_package/netpack/index.js" /* webpackChunkName: "component---src-pages-net-package-netpack-index-js" */),
  "component---src-pages-net-package-subscribe-index-js": () => import("./../../../src/pages/net_package/subscribe/index.js" /* webpackChunkName: "component---src-pages-net-package-subscribe-index-js" */),
  "component---src-pages-products-detail-index-js": () => import("./../../../src/pages/products/detail/index.js" /* webpackChunkName: "component---src-pages-products-detail-index-js" */),
  "component---src-pages-products-index-js": () => import("./../../../src/pages/products/index.js" /* webpackChunkName: "component---src-pages-products-index-js" */),
  "component---src-pages-products-internetindonesia-index-js": () => import("./../../../src/pages/products/internetindonesia/index.js" /* webpackChunkName: "component---src-pages-products-internetindonesia-index-js" */),
  "component---src-pages-products-internetluarnegeri-index-js": () => import("./../../../src/pages/products/internetluarnegeri/index.js" /* webpackChunkName: "component---src-pages-products-internetluarnegeri-index-js" */),
  "component---src-pages-products-reseller-index-js": () => import("./../../../src/pages/products/reseller/index.js" /* webpackChunkName: "component---src-pages-products-reseller-index-js" */),
  "component---src-pages-products-simcard-index-js": () => import("./../../../src/pages/products/simcard/index.js" /* webpackChunkName: "component---src-pages-products-simcard-index-js" */)
}

