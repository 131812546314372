import { createStore as reduxCreateStore } from 'redux';
import store from 'store2';

const reducer = (state, action) => {
  let updatedCart;
  let updatedNumberOfDevices = state.numberOfDevices;

  switch (action.type) {
    case `ADD_PRODUCT`:
      updatedCart = state.shoppingCart.concat(action.order);
      if (state.numberOfDevices == 0) {
        updatedNumberOfDevices = (updatedCart.find((prod) => prod.type === 'wifi') ? 1 : 0);
      }
      store.session('shopping-cart', updatedCart);
      store.session('number-of-devices', updatedNumberOfDevices);
      return {
        ...state,
        shoppingCart: updatedCart,
        numberOfDevices: updatedNumberOfDevices
      };

    case `DEL_PRODUCT`:
      updatedCart = state.shoppingCart.filter((product) => (product.key !== action.key));
      if (state.numberOfDevices > 0) {
        updatedNumberOfDevices = (updatedCart.find((prod) => prod.type === 'wifi') ? state.numberOfDevices : 0);
      }
      store.session('shopping-cart', updatedCart);
      store.session('number-of-devices', updatedNumberOfDevices);
      return {
        ...state,
        shoppingCart: updatedCart,
        numberOfDevices: updatedNumberOfDevices
      };

    case `CLEAR_CART`:
      store.session('shopping-cart', []);
      store.session('number-of-devices', 0);
      return {
        ...state,
        shoppingCart: [],
        numberOfDevices: 0
      };

    case `UPDATE_USER_INFO`:
      const updatedUserInfo = action.userInfo;
      store.local('user-info', updatedUserInfo);
      return {
        ...state,
        userInfo: updatedUserInfo
      };
    
    case `ADD_TOAST`:
      return {
        ...state,
        showProductToast: true,
        toastMessage: action.message,
        toastMethod: action.method
      }

    case `DEL_TOAST`:
      return {
        ...state,
        showProductToast: false,
        toastMessage: '',
        toastMethod: ''
      }

    case `CHANGE_NUMBER_OF_DEVICES`:
      store.session('number-of-devices', action.qty);
      return {
        ...state,
        numberOfDevices: action.qty
      }
      
    case `CHANGE_REFERRAL_CODE`:
      store.session('referral-code', action.code);
      return {
        ...state,
        referralCode: action.code
      }

    case `CHANGE_NET_IMEI`:
      store.session('net-imei', action.imei);
      return {
        ...state,
        netImei: action.imei
      }

    case `CHANGE_PROMO_CODE`:
      return {
        ...state,
        promoCode: action.code
      }
  
    default:
      break;
  }

  return state;
}

let shoppingCart = store.session('shopping-cart');
if (!shoppingCart) shoppingCart = [];

let userInfo = store.local('user-info');
if (!userInfo) userInfo = {};

let referralCode = store.session('referral-code');
if (!referralCode) referralCode = '';

let numberOfDevices = store.session('number-of-devices');
if (!numberOfDevices) numberOfDevices = 0;

let netImei = store.session('net-imei');
if (!netImei) netImei = '';

const initialState = {
  shoppingCart,
  userInfo,
  referralCode,
  promoCode: '',
  showProductToast: false,
  toastMessage: '',
  toastMethod: '',
  numberOfDevices,
  netImei,
}

const createStore = () => reduxCreateStore(reducer, initialState)
export default createStore